<template>
  <div>
    <h1>Verify OTP</h1>
    <p class="body-2 mt-3 grey--text">
      Enter Your OTP code ypu receive via email ({{ $route.query.email }})
    </p>

    <v-form v-model="isValid" ref="form" @submit.prevent="verifyOtp">
      <v-text-field
        v-model="otp_code"
        class="input-field"
        placeholder="000000"
        type="number"
        :max="6"
        :rules="[rules.required, rules.length]"
      ></v-text-field>

      <Button
        block
        large
        class="my-10"
        @click="verifyOtp"
        :loading="loading.user"
        :disabled="!isValid"
        type="submit"
        >Verify</Button
      >
    </v-form>
  </div>
</template>

<script>
import { Button } from "@/components";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");

export default {
  name: "Verify-otp",
  components: { Button },
  data() {
    return {
      isValid: false,
      otp_code: "",
      rules: {
        required: value => !!value || "Field is required.",
        length: val => val.length == 6 || "6 number required"
      }
    };
  },
  computed: {
    ...mapState(["loading"])
  },
  methods: {
    ...mapActions(["VERIFY_OTP"]),
    verifyOtp() {
      if (this.$refs.form.validate()) {
        const { email } = this.$route.query;
        this.VERIFY_OTP({ email, otp: this.otp_code });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.email) {
      4;
      next("/forgot-password");
    } else {
      next();
    }
  }
};
</script>

<style>
.input-field input {
  text-align: center;
  letter-spacing: 1rem;
}
</style>
